<script>
import Swal from "sweetalert2";
import * as moment from "moment";
import { email, required, requiredIf } from "vuelidate/lib/validators";
import DatePicker from "vue2-datepicker";
import Layout from "../../layouts/main";
import Breadcrumb from '../../../components/breadcrumb.vue';

import {
  CREATE_PAYOUTS,
  UPDATE_PAYOUTS,
  FETCH_VIRTUAL_ACCOUNTS,
  FETCH_BENEFICIARY_TABLE,
  FETCH_PAYOUT_DETAILS
} from "@/state/actions.type";

export default {
    components: {
        DatePicker,
        Layout,
        Breadcrumb
    },
    validations: {
        recepient_email: { email },
        payoutCreation: {
            model: { required },
            amount: { required },
            currency: { required },
            account_no: { required },
            channel_key: { required },
            remarks: { required },
            txn_ref: { required },
            transaction_type: { requiredIf: requiredIf(function (nestedModel) {
                return nestedModel.channel_key === 'PAYNAMICS_PAYOUT';
              })
            },
            fund_source: { requiredIf: requiredIf(function (nestedModel) {
                return nestedModel.channel_key === 'PAYNAMICS_PAYOUT';
              })
            },
            recipient_emails: {
              $each: {
                email
              }
            }
        },
    },
    data() {
        return {
            payoutCreation: {
                split_payouts: true,
                virtual_acc_no: "",
                key: "",
                channel_key: "",
                model: "",
                account_no:"",
                name: "",
                amount: "",
                trigger_time: "",
                currency: "",
                remarks: "",
                transaction_type: "",
                fund_source: "",
                send_email: false,
                send_sms: false,
                recipient_emails: [],
                txn_ref: `txnref_${(this._uid + this.$uuid.v1())
                .replace(/-/g, "")
                .substring(1, 13)
                .toUpperCase()}${this._uid}`,
            },
            isEdit:false,
            recepient_email: "",
            beneficiaryList: [],
            submitted: false,
            fundSources: {
              "CASH_OTC_BRNCH":	"Funds originated from a person depositing cash from physical agent or branch.",
              "CASH_OTC_CVS":	"Funds originated from a person depositing cash from a convenience store.",
              "CASH_OTC_ATM":	"Funds originated from a person depositing cash from an ATM deposit.",
              "EP_BT": "Funds originated from an Online Bank Transfer. (Push)",
              "EP_ACH_DEBIT": "Funds originated from an ACH Debit. (Pull)",
              "EP_DC": "Funds originated from a Debit Card.",
              "EP_CC": "Funds originated from a Credit Card.",
              "EP_EM": "Funds originated from an E-Money source",
            }
        }
    },

    created() {
      this.$store.dispatch(`virtualaccount/${FETCH_VIRTUAL_ACCOUNTS}`);
      this.$store.dispatch(`beneficiary/${FETCH_BENEFICIARY_TABLE}`, {});
      if(this.$route.query.ref) {
            this.isEdit = true;
            this.$store.dispatch(`payouts/${FETCH_PAYOUT_DETAILS}`, {
                ref: this.$route.query.ref
            }).then(response => {
                if(response.content.length > 0) {
                    this.editPayout(response.content[0]);
                }
            }).catch(error => {
                console.log(error);
            });

        } 
    },

    computed: {
        getBeneficiaryData() {
            let beneficiaryList = this.$store.state.beneficiary.content;
            let list = [];
            for (let b = 0; b < (beneficiaryList || []).length; b++) {
                list.push({
                  ...beneficiaryList[b].bank_accounts,
                  payout_channel: beneficiaryList[b].payout_channel,
                  name: beneficiaryList[b].name,
                  uuid: beneficiaryList[b].uuid,
                  model: beneficiaryList[b].uuid + "-" + beneficiaryList[b].bank_accounts.account_number,
                })
            }
            return list;
        },

        virtualAccounts() {
            return this.$store.state.virtualaccount.virtualAccounts;
        },

        isAmountValid() {
          if(this.payoutCreation.channel_key == 'GBPRIMEPAY_PAYOUT') {
            if((this.isEdit || !this.payoutCreation.split_payouts) && this.payoutCreation.amount > 700000) {
              return false;
            }
          }
          return true;
        },
        
        items() {
          return [
            {
              text: this.$t("menuitems.payouts.list.payouts"),
              to: { name: 'Payouts' }
            },
            {
              text:  this.$route.name == "Update Payout" ? "Update Payout" : this.$t("views.payouts.create-payouts"),
              active: true,
            },
          ];
        }
    },

    filters: {
        schedule_date(value) {
            return value ? moment(value).format("DD MMM, YYYY hh:mm A") : '';
        },
    },

    methods: {
        addRecepientEmail() {
          this.$v.recepient_email.$touch();
          if(this.$v.recepient_email.$anyError) {
            return false;
          }

          if(!this.payoutCreation.recipient_emails.includes(this.recepient_email)) {
            this.payoutCreation.recipient_emails.push(this.recepient_email);
          }
          this.recepient_email = "";
        },
        removeRecepientEmail(email) {
          this.payoutCreation.recipient_emails = this.payoutCreation.recipient_emails.filter(e => e !== email);
        },
        validateState(name) {
            const { $dirty, $error } = this.$v.payoutCreation[name];
            return $dirty ? !$error : null;
        },

        editPayout(item) {
          console.log(item);
            this.payoutCreation.account_no = this.virtualAccounts.find(
                (acc) => acc.account_no === item.vaaccount_no
            );
            this.selectcurrandkey(this.payoutCreation.account_no);
            this.payoutCreation.model = this.beneficiaryList.find((beneficiary) => (
              beneficiary.account_number === item.to_account_no && beneficiary.account_name === item.account_name && beneficiary.bank_name === item.bank_name
            ));
            this.payoutCreation.channel_key =
                this.payoutCreation.account_no.channel_key;
            this.payoutCreation.txn_ref = item.merchant_txn_ref;
            this.payoutCreation.merchant_txn_ref = item.txn_ref;
            this.payoutCreation.amount = item.amount;
            this.payoutCreation.trigger_time = new Date(item.due_date);
            this.payoutCreation.currency = item.currency;
            this.payoutCreation.remarks = item.remarks;
            this.payoutCreation.send_email = item.send_email;
            this.payoutCreation.send_sms = item.send_sms;
            this.payoutCreation.user_message = item.user_message;
            this.payoutCreation.transaction_type = item.transaction_type;
            this.payoutCreation.fund_source = item.fund_source;
            this.payoutCreation.recipient_emails = item.recipient_emails || [];
        },

        selectcurrandkey(value) {
            this.payoutCreation.model = null;

            if(!value) {
                return;
            }
            this.beneficiaryList = this.getBeneficiaryData.filter((benefiary) => benefiary.payout_channel == this.payoutCreation.account_no.channel_key);

            this.$store.state.virtualaccount.virtualAccounts.map((element) => {
                if (element.account_no == this.payoutCreation.account_no.account_no) {
                this.payoutCreation.channel_key = element.channel_key;
                this.payoutCreation.currency = element.currency;
                }
            });

            if (this.payoutCreation.account_no.channel_key === "BBL_PAYOUT") {
                Swal.fire(`Please ensure you have sufficient balance in your account`);
            }
        },

        handleSubmit() {
            this.submitted = true;
            this.$v.$touch();
            if (this.$v.$anyError || !this.isAmountValid) {
                return;
            }

            if(this.recepient_email) {
              this.addRecepientEmail();
            }

            const selectedBenificery = this.beneficiaryList.find(
                (b) => b.model == this.payoutCreation.model.model
            );

            const selectedVirtualAccount = this.virtualAccounts.find(
                (b) => b.account_no == this.payoutCreation.account_no.account_no
            );

            if (!this.payoutCreation.trigger_time) {
                this.payoutCreation.trigger_time = new Date();
            }
            const txnDetails = {
                virtual_acc_no: selectedVirtualAccount.account_no,
                txn_ref: this.payoutCreation.merchant_txn_ref ? this.payoutCreation.merchant_txn_ref :this.payoutCreation.txn_ref,
                bank_details: {
                account_no: selectedBenificery.account_number,
                account_type: selectedBenificery.account_type,
                account_name: selectedBenificery.account_name,
                bank_no: selectedBenificery.bank_no,
                bank_name: selectedBenificery.bank_name,
                },
                amount: parseFloat(this.payoutCreation.amount),
                trigger_time: moment(this.payoutCreation.trigger_time).format(
                "YYYY-MM-DDTHH:mm:ssZ"
                ),
                currency: this.payoutCreation.currency,
                remarks: this.payoutCreation.remarks,
                send_email: this.payoutCreation.send_email,
                send_sms: this.payoutCreation.send_sms,
                user_message: this.payoutCreation.user_message,
                transaction_type: this.payoutCreation.transaction_type,
                fund_source: this.payoutCreation.fund_source,
                recipient_emails: this.payoutCreation.recipient_emails
            };

            const payload = {
                channel_key: this.payoutCreation.channel_key,
                txn_details: txnDetails
            };
            if(!this.isEdit && this.payoutCreation.channel_key == 'GBPRIMEPAY_PAYOUT') {
              payload.split_payouts = this.payoutCreation.split_payouts;
            }

            const createOrEdit = this.isEdit ? `payouts/${UPDATE_PAYOUTS}` : `payouts/${CREATE_PAYOUTS}`;

            this.$store.dispatch(createOrEdit, payload)
            .then((result) => {
                this.$notify({
                    type: "success",
                    text: result.message,
                    closeOnClick: true,
                });
                this.$v.$reset();
                setTimeout(() => {
                  this.$router.push({name: 'Payouts'});
                }, 500);
            })
            .catch((error) => {
                const err = JSON.parse(error.message);
                this.$notify({
                    type: "error",
                    text: err.message,
                    closeOnClick: true,
                });
            });
        },
        currentDate(){
          return moment().format("DD MMM,YYYY")
        },
    }
};
</script>
<template>
<Layout>
    <div class="d-flex" style="height: 44px;">
      <breadcrumb :items="items" :goBackTo="{ name: 'Payouts' }" />
      <div class="ml-auto">
        <router-link class="text-chai font-16px" :to="{ name: 'Payouts' }">
          <b-btn
            variant="white"
            style="padding: 13px 16px;height: 44px;border: 1px solid #000;margin-right: 8px;"
          >
            cancel
          </b-btn>
        </router-link>
        <b-btn
          variant="primary"
          @click="handleSubmit"
          style="height: 44px;padding: 13px 16px;"
        >{{ $t('button.save') }}</b-btn>
      </div>
    </div>
     <div style="margin-top: 25px;">
      <form class="row" id="modal">
        <div class="col-lg-8 col-8">
          <div class="top-section">
            <div class="top-section-header">
                <div style="font-size: 16px;color: #000000;font-weight: 350;">{{ $route.name == "Update Payout" ? "Update Payout" : "Create New Payout" }}</div>
                <div style="font-size: 12px;color: #00000080;margin-top: 10px;font-weight: 325;">{{ $route.name == "Update Payout" ? "Update Payout" : "Create New Payout" }} to transfer payment to your team.</div>
            </div>
            <div class="top-section-body" style="padding: 20px 20px 20px 10px;">
              <div class="col-lg-12">
                <div class="form-group">
                  <div style="display: inline-block;margin-right: 8px;width: 70%; vertical-align: top;">
                    <label for="Amount">
                      {{ $t("views.payments.transactions.amount") }}
                      <span class="text-danger mr-1">*</span>
                    </label>
                      <b-form-input
                        step="any"
                        type="number"
                        placeholder="Amount"
                        name="amount"
                        v-model.number="payoutCreation.amount"
                        :class="{
                          'is-invalid': submitted && ($v.payoutCreation.amount.$error || !isAmountValid),
                        }"
                      />
                      <div
                        v-if="submitted && !$v.payoutCreation.amount.required"
                        class="invalid-feedback"
                      >
                        Amount is required
                      </div>
                      <div
                        v-if="submitted && !isAmountValid"
                        class="invalid-feedback"
                      >
                        Amount must be less than THB 700,000
                      </div>
                  </div>
                  <div style="display: inline-block;width: calc(30% - 8px); vertical-align: top;">
                    <label for="Currency">
                      {{ $t("views.payouts.currency") }}
                      <span class="text-danger mr-1">*</span>
                    </label>

                    <!-- <select
                        name="Currency"
                        v-model="payoutCreation.currency"
                        class="form-control"
                        disabled
                        :class="{
                        'is-invalid':
                            submitted && $v.payoutCreation.currency.$error,
                        }"
                    >
                        <option value="VND">VND</option>
                        <option value="THB">THB</option>
                    </select> -->
                    <b-input :value="payoutCreation.currency" disabled readonly />
                    <div
                      v-if="submitted && !$v.payoutCreation.currency.required"
                      class="invalid-feedback"
                    >
                      {{ $t("views.payouts.required_field_msg.currency") }}
                    </div>
                  </div>
                </div>

                <div class="form-group">
                  <label for="From Virtual Account">
                    {{ $t("views.payouts.from-va") }}
                    <span class="text-danger mr-1">*</span>

                    <i class="ri-information-line ml-1" id="Virtual"></i>
                    <b-tooltip target="Virtual">
                      {{ $t("views.payouts.virtual_account_msg") }}
                    </b-tooltip>
                  </label>

                  <b-form-select
                    v-model="$v.payoutCreation.account_no.$model"
                    :state="validateState('account_no')"
                    @change="selectcurrandkey"
                  >
                    <b-form-select-option :value="null">{{
                      $t("views.payouts.select_virtual_account")
                    }}</b-form-select-option>
                    <b-form-select-option
                      v-for="(acc, index) in virtualAccounts"
                      :key="index"
                      :value="acc"
                      >{{
                        `${acc.account_name} / ${acc.account_no}`
                      }}</b-form-select-option
                    >
                  </b-form-select>
                  <div
                    v-if="submitted && $v.payoutCreation.account_no.$error"
                    class="invalid-feedback"
                  >
                    {{ $t("views.payouts.required_field_msg.acc_number") }}
                  </div>
                </div>
                <div class="form-group">
                  <label for="Transaction Reference">
                    {{ $t("views.payouts.transaction-ref") }}
                    <span class="text-danger mr-1">*</span>

                    <i class="ri-information-line ml-1" id="Transaction"></i>
                    <b-tooltip target="Transaction">
                      {{ $t("views.payouts.unique_ref_msg") }}
                    </b-tooltip>
                  </label>

                  <input
                    type="text"
                    class="form-control"
                    placeholder="Transaction Reference"
                    name="Transaction Reference"
                    v-model="payoutCreation.txn_ref"
                    :disabled="isEdit"
                    :class="{
                      'is-invalid': submitted && $v.payoutCreation.txn_ref.$error,
                    }"
                  />
                  <div
                    v-if="submitted && !$v.payoutCreation.txn_ref.required"
                    class="invalid-feedback"
                  >
                    {{ $t("views.payouts.required_field_msg.transaction_ref") }}
                  </div>
                </div>

                <div class="form-group">
                  <label for="Transaction Remarks">
                    {{ $t("views.payouts.transaction-remarks") }}
                    <span class="text-danger mr-1">*</span>
                    <i class="ri-information-line ml-1" id="Remarks"></i>
                    <b-tooltip target="Remarks">
                      {{ $t("views.payouts.transaction_remark_msg") }}
                    </b-tooltip>
                  </label>

                  <input
                    type="text"
                    class="form-control"
                    placeholder="Transaction Remarks"
                    name="Transaction Remarks"
                    v-model="payoutCreation.remarks"
                    :class="{
                      'is-invalid': submitted && $v.payoutCreation.remarks.$error,
                    }"
                  />
                  <div
                    v-if="submitted && !$v.payoutCreation.remarks.required"
                    class="invalid-feedback"
                  >
                    {{ $t("views.payouts.required_field_msg.transaction_remark") }}
                  </div>
                </div>
                <div class="form-group">
                  <label for="Beneficiary">
                    {{ $t("views.payouts.beneficiary") }}
                    <span class="text-danger mr-1">*</span>

                    <i class="ri-information-line ml-1" id="Beneficiary"></i>
                    <b-tooltip target="Beneficiary">
                      {{ $t("views.payouts.fund_receiver_tooltip") }}
                    </b-tooltip>
                  </label>

                  <b-form-select
                    v-model="$v.payoutCreation.model.$model"
                    :state="validateState('model')"
                  >
                    <b-form-select-option :value="null">{{
                      $t("views.payouts.search_beneficiary")
                    }}</b-form-select-option>
                    <b-form-select-option
                      v-for="(beneficiary, index) in beneficiaryList"
                      :key="index"
                      :value="beneficiary"
                      >{{
                        `${beneficiary.name} / ${beneficiary.account_number}`
                      }}</b-form-select-option
                    >
                  </b-form-select>
                  <div
                    v-if="submitted && $v.payoutCreation.model.$error"
                    class="invalid-feedback"
                  >
                    {{ $t("views.payouts.required_field_msg.beneficiary") }}
                  </div>
                </div>

                <div class="form-group">
                  <label for="Beneficiary">
                    {{ $t("views.payouts.schedule-payout") }}

                    <i class="ri-information-line ml-1" id="Schedule"></i>
                    <b-tooltip target="Schedule">
                      {{ $t("views.payouts.schedule_tooltip") }}
                    </b-tooltip>
                  </label>

                  <date-picker
                    type="datetime"
                    :disabled-date="
                      (date) =>
                        date < new Date(new Date().getTime() - 24 * 60 * 60 * 1000)
                    "
                    placeholder="Select Date"
                    format="DD-MM-YYYY hh:mm"
                    id="c"
                    name="date"
                    v-model="payoutCreation.trigger_time"
                  ></date-picker>
                </div>

                <div class="form-group">
                  <label for="Channel Key">
                    {{ $t("views.payouts.channel_key") }}
                    <span class="text-danger mr-1">*</span>

                    <i class="ri-information-line ml-1" id="ChannelKey"></i>
                    <b-tooltip target="ChannelKey">
                      {{ $t("views.payouts.channel_key_tooltip") }}
                    </b-tooltip>
                  </label>

                  <input
                    type="text"
                    class="form-control"
                    placeholder="Channel Key"
                    name="Channel Key"
                    disabled
                    v-model="payoutCreation.channel_key"
                    :class="{
                      'is-invalid': submitted && $v.payoutCreation.channel_key.$error,
                    }"
                  />
                  <div
                    v-if="submitted && !$v.payoutCreation.channel_key.required"
                    class="invalid-feedback"
                  >
                    {{ $t("views.payouts.required_field_msg.channel_key") }}
                  </div>
                </div>
                <div class="form-group" v-if="payoutCreation.channel_key === 'PAYNAMICS_PAYOUT'">
                  <label for="transaction_type">
                    Transaction Type
                    <span class="text-danger mr-1">*</span>

                    <i class="ri-information-line ml-1" id="Virtual"></i>
                    <b-tooltip target="Virtual">
                      Transaction Type
                    </b-tooltip>
                  </label>

                  <b-form-select
                    v-model="$v.payoutCreation.transaction_type.$model"
                    :state="validateState('transaction_type')"
                  >
                    <b-form-select-option :value="null">Select Transaction Type</b-form-select-option>
                    <b-form-select-option value="SBINSTAPAY">INSTAPAY</b-form-select-option>
                    <b-form-select-option value="SBPESONET">PESONET</b-form-select-option>
                  </b-form-select>
                  <div
                    v-if="submitted && $v.payoutCreation.transaction_type.$error"
                    class="invalid-feedback"
                  >
                    Transaction Type is required
                  </div>
                </div>
                <div class="form-group" v-if="payoutCreation.channel_key === 'PAYNAMICS_PAYOUT'">
                  <label for="fund_source">
                    Fund Source
                    <span class="text-danger mr-1">*</span>

                    <i class="ri-information-line ml-1" id="Virtual"></i>
                    <b-tooltip target="Virtual">
                      Fund Source
                    </b-tooltip>
                  </label>

                  <b-form-select
                    v-model="$v.payoutCreation.fund_source.$model"
                    :state="validateState('fund_source')"
                  >
                    <b-form-select-option :value="null">Select Fund Source</b-form-select-option>
                    <b-form-select-option
                      v-for="(source, key) in fundSources"
                      :key="key"
                      :value="key"
                      >{{ source }}</b-form-select-option
                    >
                  </b-form-select>
                  <div
                    v-if="submitted && $v.payoutCreation.fund_source.$error"
                    class="invalid-feedback"
                  >
                    Fund Source is required
                  </div>
                </div>
                <div class="form-group">
                  <label for="user_message">
                    User Message
                    <i class="ri-information-line ml-1" id="userMessage"></i>
                    <b-tooltip target="userMessage"> User Message </b-tooltip>
                  </label>
                  <input
                    type="text"
                    class="form-control"
                    placeholder="User Message"
                    name="user_message"
                    v-model="payoutCreation.user_message"
                  />
                </div>

                <div style="margin-bottom: 10px;" class="d-flex">
                  <div class="w-50">
                    <b-form-checkbox
                      v-model="payoutCreation.send_email"
                      :value="true"
                      class="mr-2"
                      :unchecked-value="false"
                    >
                      Send Email
                    </b-form-checkbox>
                  </div>
                  <div class="w-50">
                    <b-form-checkbox
                      v-model="payoutCreation.send_sms"
                      :value="true"
                      :unchecked-value="false"
                    >
                      Send SMS
                    </b-form-checkbox>
                  </div>
                </div>
                <div>
                  <label for="recepient_email">
                      Recipient Emails
                      <i class="ri-information-line ml-1" id="recepient_email"></i>
                      <b-tooltip target="recepient_email"> Recipient Emails </b-tooltip>
                    </label>
                  <div class="email-list" :class="{'invalid': ($v.recepient_email.$anyError || $v.payoutCreation.recipient_emails.$anyError)}">
                      <b-form-input
                          v-model="recepient_email"
                          type="email"
                          placeholder="Add Email Here..."
                          class="border-0 m-2 w-75"
                          @keypress.enter="addRecepientEmail"
                      ></b-form-input>
                      <div class="d-flex flex-wrap">
                          <div class="email-text" v-for="(item, index) in payoutCreation.recipient_emails" :key="index">
                              {{ item }}
                              <span class="ml-2 cursor-pointer" @click="removeRecepientEmail(item)">
                                  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                                      <g clip-path="url(#clip0_1_70383)">
                                          <path d="M6.8775 11.1225L11.1225 6.8775M11.1225 11.1225L6.8775 6.8775M6.75 16.5H11.25C15 16.5 16.5 15 16.5 11.25V6.75C16.5 3 15 1.5 11.25 1.5H6.75C3 1.5 1.5 3 1.5 6.75V11.25C1.5 15 3 16.5 6.75 16.5Z" stroke="black" stroke-opacity="0.3" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                      </g>
                                      <defs>
                                          <clipPath id="clip0_1_70383">
                                          <rect width="18" height="18" fill="white"/>
                                          </clipPath>
                                      </defs>
                                  </svg>
                              </span>
                          </div>
                      </div>
                  </div>
                  <div class="invalid-feedback" :class="{'d-block': ($v.recepient_email.$anyError || $v.payoutCreation.recipient_emails.$anyError)}">Email is invalid.</div>
                </div>
                <b-form-checkbox
                    class="my-3"
                    v-if="!isEdit && payoutCreation.channel_key == 'GBPRIMEPAY_PAYOUT'"
                    v-model="payoutCreation.split_payouts"
                    :value="true"
                    :unchecked-value="false"
                  >
                    Automatically split payouts with amount greater than THB 700,000
                  </b-form-checkbox>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-4">
            <div style="padding: 25px;box-shadow: 0px 4px 15px 1px rgba(220, 218, 213, 0.30);border-radius: 12px;border: 1px rgba(220, 218, 213, 0.50) solid;">
                <p style="font-size: 20px;color: #FC6B2D; margin-bottom: 25px;}">Payout Summary</p>
                <label style="color: black;font-size: 14px;margin-bottom: 10px;">Amount</label>
                <b-input readonly v-if="payoutCreation.currency" :value="(payoutCreation.amount ? parseFloat(payoutCreation.amount) : 0).toLocaleString('en-US', { style: 'currency', currency: payoutCreation.currency })" style="border-radius: 12px;border: 1px solid #dcdad5;height: 58px;padding: 16px 24px;color: #000;font-size: 20px;line-height: 24px;"></b-input>
                <div style="border-top: 1px solid rgba(220, 218, 213, 0.5); margin-bottom: 25px; margin-top: 25px;"></div>
                <div style="margin-bottom: 16px;" class="summary-details-section">
                  <div class="summary-name">Virtual Account</div>
                  <div v-if="payoutCreation.account_no" class="summary-details">{{payoutCreation.account_no.account_no}}</div>
                </div>
                <div style="margin-bottom: 16px;" class="summary-details-section">
                  <div class="summary-name">Transaction Reference</div>
                  <div class="summary-details">{{payoutCreation.txn_ref}}</div>
                </div>
                <div style="margin-bottom: 16px;" class="summary-details-section">
                  <div class="summary-name">Beneficiary</div>
                  <div v-if="payoutCreation.model" class="summary-details">{{payoutCreation.model.name}}</div>
                </div>
                <div class="summary-details-section">
                  <div class="summary-name">Transaction Remarks</div>
                  <div class="summary-details">{{payoutCreation.remarks}}</div>
                </div>
                
                <div style="border-top: 1px solid rgba(220, 218, 213, 0.5); margin-bottom: 25px; margin-top: 25px;"></div>
                <p style="font-size: 14px;color: #000000; margin-bottom: 12px;}">Payout Details</p>
                <div class="summary-details d-flex" style="color: #00000080;font-size: 12px; line-height:16px;">
                    <div>Created At</div>
                    <div style="margin-left: auto;order: 2; color: #000;">{{  currentDate() }}</div>
                </div>
                <div class="summary-details d-flex" style="color: #00000080;font-size: 12px; line-height:16px; margin-top: 8px;">
                    <div>Channel Key</div>
                    <div style="margin-left: auto;order: 2; color: #000;">{{payoutCreation.channel_key}}</div>
                </div>
                <div class="summary-details d-flex" style="color: #00000080;font-size: 12px; line-height:16px; margin-top: 8px;">
                    <div>Schedule On</div>
                    <div style="margin-left: auto;order: 2; color: #000;"> {{  payoutCreation.trigger_time | schedule_date }}</div>
                </div>
            </div>
        </div>
      </form>
     </div>
  </Layout>
</template>

<style scoped>
.top-section-header{
  background: #dcdad54d;
  padding: 18px 24px;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  border-bottom: 1px #DCDAD5 solid;
  }
.email-list {
  border-radius: 15px;
  border: 1px solid #DCDAD5;
  min-height: 44px;
}
.email-text {
  border-radius: var(--8, 8px);
  border: 1px solid rgba(220, 218, 213, 0.50);
  background: rgba(220, 218, 213, 0.30);
  padding: 11px 12px;
  color: #000;
  font-size: 14px;
  font-style: normal;
  font-weight: 350;
  line-height: normal;
  margin: 10px;
}
.invalid {
  border-color: #ff3d60;
}
.top-section{
    box-shadow: 0px 4px 12px 1px rgba(220, 218, 213, 0.30);
    border-radius: 15px;
}
::v-deep .top-section label{
    color: black;
    font-size: 14px;
    margin-bottom: 10px;
}
.top-section .form-group{
    margin-bottom: 20px;
}
::v-deep .top-section select, ::v-deep .top-section input, ::v-deep .top-section textarea{
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 12px;
    padding-bottom: 12px;
    font-size: 14px;
    color: #000;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 12px;
    overflow: hidden;
    border: 1px #DCDAD5 solid;
    height: auto;
}
.top-section select{
    padding-top: 13.5px;
    padding-bottom: 13.5px;
}
.top-section .flag_images{
    position: absolute;
    width: 28px;
    top: 36px;
    left: 20px;
    border-radius: 4px;
}
.summary-details-section .summary-name{
    color: rgba(0, 0, 0, 0.50);
    font-size: 12px;
    line-height: 20px;
}
.summary-details-section .summary-details{
    font-size: 14px;
    line-height: 20px;
}
</style>